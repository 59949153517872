<div class="popup" *ngIf="!unsupportedBrowser && (showAvisoFarmacovigilancia$ | async)">
  <div class="popup-content">
    <div class="popup-header">
      <h1>Acceso exclusivo a profesionales sanitarios</h1>
    </div>
    <div class="popup-body">
      <p>Con exclusión del uso de la dirección electrónica abajo indicada para la notificación de acontecimientos adversos, Laboratorios Ferring le informa que los contenidos del sitio web al que está intentando acceder, están dirigidos exclusivamente a profesionales sanitarios facultados para la prescripción o dispensación de medicamentos.</p>
      <p><strong><i>Aviso de farmacovigilancia:</i></strong></p>
      <p>Este canal no ha sido diseñado para la notificación de efectos adversos. Si presenta o tiene conocimiento de un efecto adverso relacionado con alguno de nuestros productos, puede notificarlo:</p>
      <ul>
        <li>directamente a Ferring S.A.U. en el email <a href="mailto:Safety.MailboxSpain@ferring.com">Safety.MailboxSpain@ferring.com</a>  o en el teléfono 689 00 98 66 ó</li>
        <li>a la Agencia Española del Medicamento y Productos Sanitarios en las siguientes direcciones:
          <ul>
            <li>si es un medicamento de Ferring SAU en: <a href="https://www.notificaram.es" target="_blank">www.notificaram.es</a></li>
            <li>si es un Producto sanitario de Ferring en:  <a href="https://notificaps.aemps.es/enviotelematico/notificaps/notifica/inicio.do" target="_blank">https://notificaps.aemps.es/enviotelematico/notificaps/notifica/inicio.do</a></li>
          </ul>
        </li>
      </ul>
      <p>Nuestro Aviso de Privacidad de Farmacovigilancia explica cómo manejamos en Ferring SAU el tratamiento de datos personales cuando alguien contacta con nosotros en relación a una notificación de un acontecimiento adverso.</p>
      <p>Responsable del tratamiento: Ferring, S.A.U. Fines de tratamiento: Gestión y seguimiento de la notificación formulada por la persona interesada de acuerdo con la normativa aplicable. Derechos: acceso, rectificación, supresión, oposición, limitación del tratamiento, revocación del consentimiento, portabilidad y no ser objeto de decisiones individuales automatizadas. Información detallada en <a (click)="showPoliticaPrivacidad()">política de privacidad.</a></p>
      <p><a class="button-privacidad" target="_blank" title="Aviso de Privacidad de Farmacovigilancia" href="assets/pharmacovigilance_privacy_notice_es.pdf">Aviso de Privacidad de Farmacovigilancia</a></p>
    </div>
    <div class="popup-footer">
      <button type="button" (click)="closeAvisoFarmacovigilancia()">Aceptar</button>
    </div>
  </div>
</div>

<div class="popup popup-politica" *ngIf="!unsupportedBrowser && (showPopupPolitica$ | async)">
  <div class="popup-content">
    <div class="popup-header">
      <h1>Política de privacidad</h1>
    </div>
    <div class="popup-body">
      <div class="modal-body">
          <h4>¿Cuál es el objeto de la Política de Privacidad?</h4>
          <p>El objeto de esta política poner en conocimiento de las personas usuarias de este sitio web www.folitropinadelta.es (en adelante sitio web o sitio), la política de Ferring en materia de tratamiento y protección de datos de carácter personal del sitio.</p>
          <h4>¿Cuál es su ámbito normativo?</h4>
          <p>A través de este sitio no se recogen datos de carácter personal ni la persona usuaria está obligada a proporcionar sus datos personales para navegar por el sitio, pudiendo llevar a cabo una navegación anónima.</p>
          <p>No obstante, cualquier tratamiento de datos que pudiera llevarse a cabo por Ferring como consecuencia de la recogida de datos a través de los canales de comunicación y contacto publicados en este sitio, quedará bajo el ámbito de aplicación de la legislación vigente en España en materia de protección de datos consistente en el Reglamento (UE) 2016/679 del parlamento europeo y del consejo de 27 de abril de 2016 relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos y por el que se deroga la Directiva 95/46/CE (Reglamento general de protección de datos o RGPD) y la Ley Orgánica de Protección de Datos y Garantía de los Derechos Digitales (LOPDGDD).</p>
          <h4>¿Quién es el responsable del tratamiento de los datos personales?</h4>
          <p>El responsable del tratamiento de los datos personales que en su caso pudieran ser recogidos y tratados es Ferring, S.A.U. (en adelante Ferring) con las siguientes direcciones y datos de contacto:</p>
          <p>Postal: c/ Arquitecto Sánchez Arcas N.º 3. 1ª planta, 28040 Madrid.</p>
          <p>Electrónica: <a href="mailto:ESO-Información@ferring.com">ESO-Información@ferring.com.</a></p>
          <p>Teléfono: +34 91 387 70 00.</p>
          <h4>¿Cómo puede contactar con nuestro delegado de protección de datos?</h4>
          <p>La persona usuaria puede contactar con nuestro delegado de protección de datos a través de DPD@ferring.com</p>
          
          <h4>¿Con qué finalidad y base jurídica trataremos sus datos personales?</h4>
          <p>Este sitio web es meramente informativo y a través del mismo no se recogen ni se tratan datos de carácter personal de las personas usuarias de la web.</p>
          <p>Cualquier dato personal que la persona usuaria comunique a Ferring lo será a través del uso de los medios y canales de contacto publicados en este sitio a título informativo. Entre estos medios de contacto está el correo electrónico y teléfono de Ferring, los cuales la persona usuaria podrá utilizar con fines generales y/o con fines de notificación de un acontecimiento adverso.</p>
          <p>En consecuencia, la persona usuaria queda informada de que, con base jurídica en el consentimiento de la persona interesada manifestado por el hecho de contactar a través de los canales generales con Ferring de forma personalizada y proporcionándonos sus datos personales, estos podrán ser tratados con el fin de atender y contestar la comunicación de la persona usuaria.</p>
          <p>Además, la persona usuaria queda informada de que, con base jurídica en el cumplimiento de las obligaciones derivadas de las normas vigentes en materia de farmaindustria, en caso en que comunique a Ferring un acontecimiento adverso sobre sus productos, sus datos personales serán tratados por Ferring para contactar con dicha persona y llegar a cabo el seguimiento de dicho acontecimiento y análisis de la seguridad de los productos con el fin de hacer posible la adopción de las medidas oportunas y asegurar, de este modo, que los medicamentos presenten la relación beneficio-riesgo favorable para la población en las condiciones de uso autorizadas.</p>
          <h4>¿Está obligada la persona usuaria a comunicar sus datos personales a Ferring para el uso de este sitio?</h4>
          <p>No. Este sitio no requiere la comunicación de datos personales a Ferring para su uso. En todo caso, si de acuerdo con el apartado anterior la persona usuaria voluntariamente contacta con Ferring y le proporciona datos personales, la persona usuaria está obligada a proporcionar datos veraces, exactos y actualizados siempre y únicamente correspondiente a su propia identidad, quedando prohibido proporcionar datos de terceros salvo que se cuente con el consentimiento, autorización y/o representación legal de dicho tercero con dicho fin.</p>
          <h4>¿A quién le comunicaremos sus datos personales?</h4>
          <p>Salvo existencia de una obligación legal de comunicación de los datos personales a las autoridades sanitarias o de otra naturaleza competentes por razón de la materia, Ferring no comunicará los datos personales de la persona usuaria que pudieran ser objeto de tratamiento según lo antedicho a ningún tercero.</p>
          <p>En todo caso, Ferring informa a la persona usuaria de que sus datos personales podrán ser tratados bajo encargo por entidades que prestan a Ferring distintos servicios auxiliares (también denominados encargados del tratamiento), así como tareas de soporte y administración de sus sistemas, a través de contratos de encargo del tratamiento y siempre siguiendo las instrucciones de Ferring, con las debidas garantías de confidencialidad y seguridad.</p>
          <p>Asimismo, en caso de tratamiento de los datos personales de la persona usuaria, Ferring no llevará a cabo transferencias internacionales de los datos personales de la persona usuaria ni a países ni a organizaciones internacionales.</p>
          <h4>¿Durante cuánto tiempo conservaremos sus datos personales?</h4>
          <p>En caso de tratamiento de los datos personales de la persona usuaria, Ferring conservará los datos mientras se mantenga la finalidad y base legítima del tratamiento y durante los plazos establecidos en las normas vigentes para el cumplimiento de obligaciones legales y atención de posibles responsabilidades.</p>
          <h4>¿Qué derechos tiene la persona usuaria?</h4>
          <p>Ferring informa a la persona usuaria de la posibilidad de ejercitar sus derechos de acceso, rectificación, supresión, oposición, limitación al tratamiento, portabilidad, así como a no ser objeto de decisiones basadas únicamente en el tratamiento automatizado de sus datos y, en su caso, retirada del consentimiento otorgado en relación con los tratamientos indicados en esta política basados en el consentimiento (sin que ello afecte a la licitud del tratamiento basada en el consentimiento previo a su retirada), mediante envío de su solicitud escrita, indicando el derecho que ejercita, junto con una copia de su DNI/NIF o documento válido en derecho que lo identifique, a las siguientes direcciones:</p>
          <p>Postal: c/ Arquitecto Sánchez Arcas N.º 3. 1ª planta, 28040 Madrid.</p>
          <p>Electrónica: <a href="mailto:derechosarco@ferring.com">derechosarco@ferring.com</a></p>
          <h4>¿Ante quién puede interponer una reclamación?</h4>
          <p>Se informa a la persona usuaria que tiene derecho a presentar una reclamación ante la Agencia Española de Protección de Datos (www.agpd.es) y dirección postal en C/ Jorge Juan, 6. 28001 – Madrid, y/o teléfono de contacto 912 663 517), así como a solicitar su tutela en relación con el ejercicio de sus derechos.</p>
          <p>Con carácter previo a la presentación de dicha reclamación en Agencia Española de Protección de Datos y de forma totalmente voluntaria, la persona usuaria puede dirigirse a Ferring a nuestro delegado de protección de datos a través de la siguiente dirección electrónica de contacto DPD@ferring.com</p>
          
          <h4>¿Qué hacer con los datos personales publicados en este sitio?</h4>
          <p>En relación con los datos personales que puedan publicarse en este sitio web (incluidas imágenes) se pone en conocimiento de la persona usuaria que dichos datos forman parte de una o varias actividades de tratamiento de datos responsabilidad de Ferring en que han sido incorporados con el previo consentimiento informado de los interesados u otras bases jurídicas lícitas con fines específicos e inequívocos y no pueden ser libremente tratados y reproducidos por la persona usuaria  u otros terceros -ni aún siquiera cuando se haga referencia al origen de los mismos- salvo que se obtenga la previa autorización y consentimiento informado de la propia persona interesada y/o el tratamiento se ampare en otra base jurídica lícita.</p>
          <p>Por tanto, todos los datos personales de cualquier naturaleza que hayan sido publicados en este sitio sólo podrán ser utilizados por la persona usuaria con el fin de participar y disfrutar de nuestro sitio web en la forma establecida en la presente política, o en su caso condiciones generales y particulares que lo regulen y en el marco de la legalidad vigente, quedando expresamente prohibido cualesquiera otros usos distintos a los señalados, incluida la incorporación de las imágenes o los datos a ficheros o tratamientos y/o creación de bases de datos de personas y/o envío de publicidad o cesión a terceros, sin el previo consentimiento informado de la persona interesada titular de las informaciones y datos, no siendo responsable Ferring de los usos que la persona usuaria u otros terceros efectúen contraviniendo lo establecido en estas condiciones y otras que los regulen.</p>
          <p>En ningún momento la persona usuaria debe considerar que este sitio web de Ferring es una fuente accesible al público de la cual se pueda hacer una libre disposición de los datos personales, informaciones y contenidos publicados a través del mismo.</p>
          <h4>¿Qué medidas de seguridad tenemos implantadas?</h4>
          <p>Ferring informa a la persona usuaria de que, en caso de tratar sus datos personales lo hará de forma segura, aplicando las medidas técnicas y organizativas apropiadas para garantizar un nivel de seguridad adecuado al riesgo, teniendo en cuenta el estado de la técnica, los costes de aplicación, y la naturaleza, el alcance, el contexto y los fines del tratamiento, así como riesgos de probabilidad y gravedad variables para los derechos y libertades de las personas usuarias.</p>
          <h4>Sobre el uso de cookies y dispositivos de almacenamiento y recuperación de datos en equipos terminales de las personas usuarias</h4>
          <p>Ferring utiliza cookies y dispositivos de almacenamiento y recuperación de datos en equipos terminales de la persona usuaria cuando la persona usuaria navega por este sitio web.</p>
          <p>Tales cookies y dispositivos de almacenamiento y recuperación de datos son usados por Ferring bajo las condiciones y con las finalidades descritas en su <a (click)="showPoliticaCookies()">Política de Cookies.</a></p>
          <h4>Sobre la actualización de esta política</h4>
          <p>Periódicamente Ferring podrá actualizar esta Política de Privacidad. Será responsabilidad de la persona usuaria acceder periódicamente a la Política de Privacidad de Ferring publicada en este sitio web, a fin de conocer en todo momento la última versión.</p>
          <h4>¿Cómo contactar con Ferring para cualquier duda sobre esta política de privacidad?</h4>
          <p>Para cualquier consulta sobre la presente Política de Privacidad, la persona usuaria podrá dirigirse a Ferring por medio electrónico a través de la siguiente dirección de correo electrónico <a href="mailto:derechosarco@ferring.com">derechosarco@ferring.com.</a></p>
          




	      </div>
    </div>
    <div class="popup-footer">
      <button type="button" (click)="closePoliticaPrivacidad()">Aceptar</button>
    </div>
  </div>
</div>

<div class="popup" *ngIf="!unsupportedBrowser && (showPopupPoliticaUso$ | async)">
  <div class="popup-content">
    <div class="popup-header">
      <h1>Política de uso</h1>
    </div>
    <div class="popup-body">
          <h4>Objeto de esta política</h4>

          <p>Los términos y condiciones que más adelante se indican (en adelante política, condiciones generales o condiciones) regulan el acceso, registro y uso del sitio web www.folitropinadelta.es (en adelante, el sitio o sitio web), cuya explotación lleva a cabo y a través de la cual presta servicios de la sociedad de la información Ferring, S.A.U. (en adelante Ferring), con N.I.F. con NIF número A-78297140, domicilio en C/ Arquitecto Sánchez Arcas N.º 3. 1ª planta, 28040 Madrid y dirección electrónica de contacto <a href="mailto:ESO-Información@ferring.com">ESO-Información@ferring.com</a> inscrita en el Registro Mercantil de Madrid al tomo 1.121 general, de la sección 3ª del Libro de Sociedades, hoja M-38671, Tomo 2187, Folio 1, Inscripción 9ª y adherida al Código de Buenas Prácticas de Farmaindustria (2016), que puede consultar electrónicamente a través del siguiente enlace del siguiente enlace <a href="http://www.codigofarmaindustria.org" target="_blank">www.codigofarmaindustria.org.</a></p>

          <h4>Alcance de esta política</h4>

          <p>Dentro de la expresión "sitio o sitio web" se comprenden -con carácter delimitativo pero no limitativo- los datos, textos, gráficos, imágenes, animaciones, creaciones musicales, vídeos, sonidos, dibujos, fotografías y otros incluidos en el mismo, y, en general, todas las creaciones expresadas por cualquier medio o soporte, tangible o intangible con independencia de que sean susceptibles o no de propiedad intelectual e industrial de acuerdo con las normas vigentes aplicables.</p>

          <h4>Objeto del sitio web</h4>

          <p>A través del acceso al sitio, la persona usuaria podrá disfrutar de diversos contenidos (información) sobre medicamentos de uso humano producidos por Ferring, con carácter meramente informativo y sin ánimo de sustituir las opiniones, consejos y recomendaciones del profesional.</p><p>

          </p><p>Con carácter general los contenidos ofrecidos a través del sitio web estarán disponibles en español, sin perjuicio de la posibilidad –a reserva de Ferring- de ofrecer los mismos en el resto de idiomas autonómicos oficiales, así como en cualquier otro idioma hablado en la Unión Europea o fuera de ella. </p>

          <p>Ferring podrá modificar de forma unilateral y sin aviso previo, la prestación, configuración, contenido y servicios del sitio, así como las condiciones de uso y el acceso a los contenidos ofrecidos. En cada caso, Ferring cuando corresponda, advertirá de dichos cambios en las correspondientes condiciones generales o particulares de uso, así como en otros textos de la web. </p>

          <p>El coste del acceso telefónico u otro tipo de gasto por la conexión al acceso al sitio web correrá a cargo exclusivamente de la persona usuaria. </p>

          <h4>Quien puede tener acceso a los contenidos de este sitio web</h4>

          <p>El acceso a este sitio web está limitado exclusivamente a las personas físicas que reúnan las siguientes condiciones con carácter simultáneo:</p>

          <p>Ser un profesional médico.</p>

          <p>Aquellas personas que reúnan las condiciones antedichas podrán acceder a este sitio y utilizar sus contenidos, sólo, previo registro y aceptación expresa de las presente condiciones.</p>

          <p>La aceptación de las presentes condiciones implica que la persona usuaria adquiere una serie de derechos y obligaciones, con objeto de garantizar el adecuado disfrute de los servicios y contenidos que se encuentran en el sitio y que, Ferring pone a disposición de la persona usuaria. </p>

          <p>La persona usuaria es consciente de que el acceso y utilización de los servicios y contenidos del sitio se realiza bajo su única y exclusiva responsabilidad.</p>

          <p>Con exclusión de lo anterior, cualquier persona puede notificar a Ferring un acontecimiento adverso relacionado con nuestros productos farmacéuticos a través de la dirección de correo electrónico publicada para ello, sin que esto sea una razón ni conlleve el acceso a los contenidos del sitio web.</p>

          <h4>Qué derechos y obligaciones tiene la persona usuaria del sitio web</h4>

          <p>La persona usuaria podrá:</p>

          <p>- Acceder de forma gratuita a los contenidos y servicios del sitio disponibles como tales, en las condiciones técnicas, particulares y de registro determinadas por Ferring, tanto en estas condiciones generales o en las condiciones particulares de dichos servicios.</p>
          <p>- Utilizar los servicios y contenidos disponibles para su uso según lo dispuesto en estas condiciones generales o las condiciones particulares que regulen el uso de un determinado servicio y/o contenido destinado a colectivos específicos que en su caso determine Ferring.</p>
          <p>- La persona usuaria podrá descargar una única copia del sitio web para su visualización "off-line" con fines personales y no comerciales.</p>
          <p>- Hacer un uso correcto y lícito del sitio, de conformidad con la legislación vigente, la moral, las buenas costumbres y el orden público. </p>

          <p>En ningún caso la persona usuaria podrá:</p>

          <p>- Acceder o utilizar los servicios y contenidos del sitio con fines ilícitos, lesivos de derechos y libertades de terceros, o que puedan perjudicar, dañar o impedir por cualquier forma, el acceso a los mismos, en perjuicio de Ferring o de terceros. </p>
          <p>- Utilizar los servicios, total o parcialmente, para promocionar, vender, contratar, divulgar publicidad o información propia o de terceras personas sin autorización previa y por escrito de Ferring.</p>
          <p>- Introducir información en el sitio web o emplear los servicios existentes en el mismo con la finalidad de atentar –directa o indirectamente- contra los derechos –y muy especialmente los derechos fundamentales y libertades públicas- de otras personas usuarias del sitio web o de Ferring; que inciten o promuevan la realización de actos delictivos, xenófobos, terroristas o degradantes por razón de edad, sexo, religión o creencias; o de carácter pornográfico, obsceno, violento o que atenten contra la ley, la moral o las buenas costumbres. A estos efectos, por información se entenderá, con carácter delimitativo pero no limitativo: textos, gráficos, imágenes, vídeos, sonidos, dibujos, fotografías, datos, notas, y otros. </p>
          <p>- Incluir hipervínculos en sus páginas web particulares o comerciales a este sitio web que no se limiten única y exclusivamente al acceso a la página principal del mismo. </p>
          <p>- Utilizar los servicios y contenidos ofrecidos a través del sitio de forma contraria a las condiciones generales de uso y/o las condiciones particulares que regulen el uso de un determinado servicio y/o contenido, y en perjuicio o con menoscabo de los derechos de otras personas usuarias. </p>
          <p>- Realizar cualquier acción que impida o dificulte el acceso al sitio por otras personas usuarias, así como de los hipervínculos a los servicios y contenidos ofrecidos por Ferring o por terceros a través del sitio web. </p>
          <p>- Utilizar el sitio web como vía de acceso a Internet para la comisión de acciones ilícitas o contrarias a la legislación vigente, la moral, las buenas costumbres y el orden público. </p>
          <p>- Emplear cualquier tipo de virus informático, código, software, programa informático, equipo informático o de telecomunicaciones, que puedan provocar daños o alteraciones no autorizadas de los contenidos, programas o sistemas accesibles a través de los servicios y contenidos prestados en el sitio web o en los sistemas de información, archivos y equipos informáticos; o el acceso no autorizado a cualesquiera contenidos y/o servicios del sitio web. </p>
          <p>- Eliminar o modificar de cualquier modo los dispositivos de protección o identificación de Ferring o sus legítimos titulares sobre los contenidos alojados en el sitio web, o los símbolos que Ferring o los terceros legítimos titulares de los derechos incorporen a sus creaciones objeto de propiedad intelectual o industrial existentes en este sitio web. </p>
          <p>- Incluir en sitios web de su responsabilidad o propiedad "metatags" correspondientes a marcas, nombres comerciales o signos distintivos propiedad de Ferring. </p>
          <p>- Reproducir total o parcialmente el presente sitio web en otro sitio web; no podrá realizar enmarcados al sitio o los sitios web accesibles a través del mismo que oculten o modifiquen –con carácter delimitativo pero no limitativo- contenidos, espacios publicitarios y marcas de Ferring o de terceros, con independencia o no de que supongan actos de competencia desleal o de confusión. </p>
          <p>- Crear marcos dentro de un sitio web de su responsabilidad o propiedad que reproduzcan la página principal y/o las páginas accesibles a través de la home de este sitio web sin la previa autorización de Ferring. </p>
          <p>- Utilizar la marca, nombres comerciales, así como cualquier otro signo identificativo que se encuentre sujeto a derechos de propiedad intelectual o industrial, sin la previa autorización expresa y por escrito de su titular. </p>
          <p>- Realizar cualquier acción que suponga la reproducción, distribución, copia, alquiler, comunicación pública, transformación o cualquier otra acción similar que suponga la modificación o alteración, de todo o parte de los contenidos y servicios del sitio o la explotación económica de los mismos, sin la autorización previa y por escrito de Ferring o del tercero titular de los derechos de propiedad intelectual e industrial que recaigan sobre los servicios o contenidos del sitio web.</p>

          <h4>Qué derechos y obligaciones tiene Ferring </h4>

          <p>Ferring se reserva los siguientes derechos:</p>

          <p>- Modificar las condiciones de acceso al sitio, técnicas o no, de forma unilateral y sin preaviso, sin perjuicio de lo dispuesto en las condiciones particulares que regulen el uso de un determinado servicio y/o contenido destinado a colectivos específicos que en su caso determine Ferring. </p>
          <p>- Establecer condiciones particulares y, en su caso, la exigencia de un precio u otros requisitos para el acceso a determinados servicios y/o contenidos.  </p>
          <p>- Limitar, excluir o condicionar el acceso al sitio cuando no se den todas las garantías de utilización correcta del sitio conforme a las obligaciones y prohibiciones asumidas en esta política. </p>
          <p>- Finalizar la prestación de un servicio o suministro de un contenido, sin perjuicio de lo dispuesto en las condiciones particulares que regulen el uso de un determinado servicio y/o contenido.  </p>
          <p>- Modificar, suprimir o actualizar todo o parte de los contenidos o servicios ofrecidos a través del sitio, sin necesidad de preaviso, sin perjuicio de lo dispuesto en las condiciones particulares que regulen el uso de un determinado servicio y/o contenido.  </p>
          <p>- Emprender cualquier acción legal o judicial que resulte conveniente para la protección de los derechos tanto de Ferring como de terceros que presten sus servicios o contenidos a través del sitio, siempre que resulte procedente.  </p>
          <p>- Exigir la indemnización que pudiera derivar por el uso indebido o ilícito de todo o parte de los servicios y contenidos prestados a través del sitio.  </p>

          <h4>Normas sobre el uso de cookies </h4>

          <p>Ferring puede utilizar cookies en este sitio web. Tales cookies serán usadas por Ferring bajo las condiciones y con las finalidades descritas en su <a href="#" data-toggle="modal" data-target="#modal-politica-cookies" data-dismiss="modal">Política de Cookies.</a> </p>

          <h4>Condiciones de exención y limitación de responsabilidad de Ferring </h4>

          <p>Ferring queda exenta de cualquier tipo de responsabilidad por daños y perjuicios de toda naturaleza en los siguientes casos:</p>

          <p>- Por la imposibilidad o dificultades de conexión a la red de comunicaciones a través de la que resulta accesible este sitio web, independientemente de la clase de conexión utilizada por la persona usuaria. </p>
          <p>- Por la interrupción, suspensión o cancelación del acceso al sitio web, así como por disponibilidad y continuidad del funcionamiento del sitio o de los servicios y/o contenidos en el mismo, cuando ello se deba a una causa ajena al ámbito de control de Ferring, ya provenga directa o indirectamente de ésta. </p>
          <p>- Ferring no asume ninguna responsabilidad respecto de los servicios y contenidos, ni por la disponibilidad y condiciones, técnicas o no, de acceso a los mismos, que sean ofrecidos por terceros prestadores de servicios, en especial respecto de los prestadores de servicios de la sociedad de la información. Por prestadores de servicios de la sociedad de la información se entenderán aquellas personas físicas o jurídicas que presten los siguientes servicios al público: (a) Transmisión por una red de comunicación de datos facilitados por el destinatario del servicio. (b) Servicios de acceso a la citada red. (c) Servicios de almacenamiento o alojamiento de datos. (d) Suministro de contenidos o información. (e) Servicio de copia temporal de los datos. (f) Facilitación de enlaces a contenidos o instrumentos de búsqueda. </p>
          <p>- Ferring en ningún momento, asume responsabilidad por los daños o perjuicios que pudieran causar la información, contenidos, productos y servicios –con carácter delimitativo pero no limitativo- prestados, comunicados, alojados, transmitidos, exhibidos u ofertados por terceros ajenos a Ferring -incluidos los prestadores de servicios la sociedad de la información- a través de un sitio web al que pueda accederse mediante un enlace existente en este sitio. </p>
          <p>- Del tratamiento y utilización posterior de datos personales realizados por terceros ajenos a Ferring, así como la pertinencia de la información solicitada por éstos. </p>
          <p>- De la calidad y velocidad de acceso al sitio y de las condiciones técnicas que debe reunir la persona usuaria con el fin de poder acceder al sitio y a sus servicios y/o contenidos. </p>
          <p>- Ferring no será responsable de los retrasos o fallos que se produjeran en el acceso y/o funcionamiento de los servicios y/o contenidos del sitio web, debido a un caso de Fuerza Mayor. </p>
          <p>- La persona usuaria responderá personalmente de los daños y perjuicios de cualquier naturaleza causados a Ferring directa o indirectamente, por el incumplimiento de cualquiera de las obligaciones derivadas de estas condiciones generales u otras normas por las que se rija la utilización del sitio. </p>

          <h4>Derechos de propiedad intelectual e industrial </h4>

          <p>La persona usuaria conoce que los contenidos y servicios ofrecidos a través del sitio -incluyendo textos, gráficos, imágenes, animaciones, creaciones musicales, vídeos, sonidos, dibujos, fotografías, todos los comentarios, exposiciones y código html de la misma, sin que esta enumeración tenga carácter limitativo- se encuentran protegidos por las leyes de propiedad intelectual e industrial. El derecho de autor y de explotación económica de este sitio corresponde a Ferring y/o a terceras entidades. </p>

          <p>Las marcas, nombres comerciales o signos distintivos que aparecen en el sitio web son propiedad de Ferring o, en su caso, de terceras entidades, y se encuentran protegidos por las leyes vigentes de propiedad industrial. </p>

          <p>La prestación de los servicios y publicación de los contenidos a través del sitio no implicará en ningún caso la cesión, renuncia o transmisión, total o parcial, de la titularidad de los correspondientes derechos de propiedad intelectual e industrial por Ferring y/o sus terceros legítimos titulares. </p>

          <p>Bajo ningún concepto, la persona usuaria podrá realizar un uso o utilización de los servicios y contenidos existentes en el sitio que no sea exclusivamente personal, a salvo de las excepciones determinadas en las presentes condiciones generales de uso de este sitio o en las condiciones particulares que Ferring tenga a bien establecer para regular el uso de un determinado servicio y/o contenido ofrecido a través del sitio web. </p>

          <p>Ninguna parte de este sitio web puede ser reproducido, distribuido, transmitido, copiado, comunicado públicamente, transformado, en todo o en parte mediante ningún sistema o método manual, electrónico o mecánico (incluyendo el fotocopiado, grabación o cualquier sistema de recuperación y almacenamiento de información) a través de cualquier soporte actualmente conocido o que se invente en el futuro, sin consentimiento de Ferring. La utilización, bajo cualquier modalidad, de todo o parte del contenido del sitio queda sujeta a la necesidad de solicitar autorización previa de Ferring y/o terceros titulares legítimos y la aceptación de la correspondiente licencia, en su caso, excepto para lo dispuesto respecto de los derechos reconocidos y concedidos a la persona usuaria en estas condiciones generales o lo que así se determine en las condiciones particulares de uso de un determinado servicio y/o contenido ofrecido a través del sitio. </p>

          <p>Si la actuación u omisión culpable o negligente directa o indirectamente imputable a la persona usuaria del sitio web que origine una infracción de los derechos de propiedad intelectual e industrial de Ferring o de terceros -exista o no beneficio para el mismo- originase a Ferring daños, pérdidas, obligaciones solidarias, gastos de cualquier naturaleza, sanciones, medidas coercitivas, multas y otras cantidades surgidas o derivadas de cualquier reclamación, demanda, acción, pleito o procedimiento, sea civil, penal o administrativo, Ferring tendrá derecho a dirigirse contra la persona usuaria por todos los medios legales a su alcance y reclamar cualesquiera cantidades indemnizatorias, incluyendo– a título enunciativo y no limitativo- daños morales e imagen, daño emergente y lucro cesante, costes publicitarios o de cualquier otra índole que pudieran resultar para su reparación, importes de sanciones o sentencias condenatorias, intereses de demora, el coste de la financiación de todas las cantidades en que pudiera resultar perjudicada Ferring, las costas judiciales y el importe de la defensa en cualquier proceso en el que pudiera resultar demandada Ferring por las causas anteriormente expuestas, por los daños y perjuicios ocasionados por razón de su actuación u omisión, sin perjuicio de ejercer cualesquiera otras acciones que en derecho le corresponda. </p>

          <h4>Uso de hiperenlaces a este sitio web</h4>

          <p>El uso de hiperenlaces al presente sitio web únicamente sólo puede realizarse siempre que el hiperenlace se realice en los siguientes términos previstos en las presentes condiciones:</p>

          <p>- El enlace deberá permitir únicamente el acceso a la página de inicio de este sitio web. </p>
          <p>- Con el propósito de evitar la confusión se prohíbe cargar cualquier página perteneciente al sitio web en una de las secciones de otra página web dividida en marcos, de forma que provoque una distorsión en la presentación de este sitio web induciendo a la confusión ("framing"). </p>
          <p>- La posibilidad de insertar un enlace no presupone, en ningún caso, un consentimiento para reproducir los aspectos visuales y funcionales ("look and feel") de este sitio web. </p>
          <p>- Asimismo se prohíbe explícitamente la creación de un entorno o barra de navegación sobre las páginas que componen este sitio, sin autorización previa. </p>
          <p>- Aspecto del enlace: el hiperenlace solo podrá consistir en un texto. Para el uso de gráficos o logos deberá obtenerse previamente una licencia de uso de los gráficos o logos de Ferring. En todo caso, el texto deberá expresar rotundamente que enlaza con el presente sitio web. Con carácter general, el aspecto, el efecto visual, la ubicación y, en general, las características del hiperenlace deberán evidenciar que el mismo conduce al sitio web de Ferring y que éste es independiente y no está unido por una relación, ni de colaboración, asociación, patrocinio, laboral ni de ningún otro tipo, a la página web que contiene el hiperenlace. </p>
          <p>- Cualquier página web que contenga un hiperenlace al sitio web de Ferring, deberá respetar ineludiblemente estas condiciones generales de uso del presente sitio web, caracterizarse por ser hiperenlaces leales y lícitos y cumplir cualesquiera disposiciones legales aplicables y las exigencias de la moral y las buenas costumbres generalmente aceptadas. </p>
          <p>- En especial, la autorización para la inserción de hiperenlaces a este sitio web estará condicionada al respeto por la dignidad y la libertad humana. El sitio web en el que se establezca el hiperenlace no contendrá informaciones o contenidos ilícitos, contrarios a la moral y a las buenas costumbres y al orden público, así como tampoco contendrá contenidos contrarios a cualesquiera derechos de terceros. </p>
          <p>- Por último, queda prohibido insertar hiperenlaces al sitio web en páginas web con contenidos, alusiones u orientación contrarios a o no compatibles con los que inspiran el sitio web de Ferring. </p>

          <h4>Tratamiento de datos personales a través de este sitio web</h4>

          <p>Ferring garantiza la confidencialidad de los datos de carácter personal facilitados por las personas usuarias y su tratamiento de acuerdo a la legislación vigente sobre protección de datos de carácter personal: Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016 relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos y por el que se deroga la Directiva 95/46/CE (en adelante RGPD), la Ley Orgánica de Protección de Datos y Garantía de los Derechos Digitales (LOPDGDD) y demás normativa vigente en la materia.</p>

          <p>Los datos personales recogidos a través del sitio web o con ocasión del uso de las direcciones de contacto publicadas en el sitio formarán parte de una o varias actividades de tratamiento responsabilidad de Ferring. A tales efectos, Ferring proporcionará a las personas usuarias, con carácter previo a la entrega de los datos personales, información básica y detallada sobre el contenido del tratamiento sin perjuicio de la posibilidad de la persona usuaria de acceder a la <a href="#" data-toggle="modal" data-target="#modal-politica-privacidad" data-dismiss="modal">Política de Privacidad.</a></p>

          <h4>Duración de este sitio web y vigencia de esta política</h4>

          <p>El acceso, los contenidos y servicios ofrecidos a través del sitio tienen, en principio, una duración indefinida. Ferring no obstante podrá para dar por terminado o suspender el acceso, los servicios y/o contenidos del mismo en cualquier momento, sin perjuicio de lo que se hubiere dispuesto al respecto en estas condiciones generales o, en su caso, condiciones particulares que regulen el uso de un determinado servicio y/o contenido. </p>

          <h4>Acuerdo completo</h4>

          <p>Estas condiciones generales y las particulares que en su caso se establezcan para servicios concretos, contienen todas las condiciones establecidas por Ferring y convenidas entre ésta y la persona usuaria para el uso de este sitio con relación al objeto de las mismas y se considerarán como inexistentes cualesquiera declaraciones, compromisos o promesas, verbales, escritas o implícitas, anteriores a estas condiciones con relación al objeto de las mismas. </p>

          <p>El hecho de que Ferring no exija en un momento determinado el respeto de alguna de las condiciones establecidas en estas condiciones generales o, en su caso, condiciones particulares que regulen el uso de un determinado servicio y/o contenido, no puede ser interpretado por la persona usuaria como la renuncia a exigir ulteriormente su cumplimiento. </p>

          <h4>Nulidad y anulabilidad de las cláusulas de esta política</h4>

          <p>En el supuesto de que cualquier cláusula de estas condiciones generales o, en su caso, condiciones particulares que regulen el uso de un determinado servicio y/o contenido, resulte anulable o nula, en su totalidad o en parte, esta nulidad o anulabilidad no afectará a la validez de otras cláusulas de las mismas, las cuales permanecerán con plena eficacia y validez, salvo que la parte que alegue su nulidad o anulabilidad pruebe que sin la cláusula que resultare nula o anulable no podrán cumplirse los fines perseguidos por las presentes condiciones generales y/o particulares aplicables. </p>

          <h4>Legislación aplicable</h4>

          <p>Estas condiciones generales se rigen por la legislación española.</p>

          <h4>Jurisdicción aplicable</h4>

          <p>Las partes, con expresa renuncia a su propio fuero, se someten para la resolución de cuantos litigios pudieran derivarse a los Juzgados y Tribunales de Madrid capital. </p>

      </div>
      <div class="popup-footer">
        <button type="button" (click)="closePoliticaUso()">Aceptar</button>
      </div>
  </div>
</div>

<div class="popup" *ngIf="!unsupportedBrowser && (showAvisoLegal$ | async)">
  <div class="popup-content">
    <div class="popup-header">
      <h1>Aviso legal</h1>
    </div>
    <div class="popup-body">
		  <p>El titular de este sitio web y prestador de servicios de la sociedad de la información es Ferring, S.A.U. (en adelante Ferring) con NIF número A-78297140, domicilio en c/ Arquitecto Sánchez Arcas N.º 3. 1ª planta, 28040 Madrid.</p>
			<p>La sociedad está inscrita en el Registro Mercantil de Madrid al tomo 1.121 general, de la sección 3ª del Libro de Sociedades, hoja M-38671, Tomo 2187, Folio 1, Inscripción 9ª y adherida al Código de Buenas Prácticas de Farmaindustria (2016), que puede consultar electrónicamente a través del siguiente enlace <a href="http://www.codigofarmaindustria.org" target="_blank">www.codigofarmaindustria.org.</a></p>

			<p>Esta información legal se completa con la <a (click)="showPoliticaUso()">política de uso</a>, <a (click)="showPoliticaPrivacidad()">política de privacidad</a> y <a (click)="showPoliticaCookies()">política de cookies</a> y resto de textos legales que en su caso sean publicados en este sitio. </p>
			<p>Para cualquier consulta o contacto con Ferring, las personas interesadas pueden contactar con Ferring a través de dirección electrónica de contacto ESO-Información@ferring.com y teléfono +34 91 387 70 00. </p>
    </div>
    <div class="popup-footer">
        <button type="button" (click)="closeAvisoLegal()">Aceptar</button>
    </div>
  </div>
</div>

<div class="popup" *ngIf="!unsupportedBrowser && (showPopupPoliticaCookies$ | async)">
  <div class="popup-content">
    <div class="popup-header">
      <h1>Política de cookies</h1>
    </div>
    <div class="popup-body">
		  <h4>¿Cuál es el objeto de esta política?</h4>
			<p>Esta política tiene por objeto informar a la persona usuaria de las cookies y dispositivos de almacenamiento y recuperación de datos (dard) o tecnología equivalente que utiliza este sitio web.</p>
			<h4>¿Qué son las cookies?</h4>

			<p>Las cookies y dispositivos de almacenamiento y recuperación de datos (dard) (en adelante todos identificados como cookies) son ficheros o archivos de texto que un servidor web descarga y almacena en el ordenador/smartphone/tablet (en adelante equipo) de la persona usuaria al acceder a determinadas páginas web para almacenar y recuperar información sobre la navegación que realiza desde dicho equipo. Por ello las cookies pueden almacenar información sobre el equipo de la persona usuaria o sobre sus sesiones de navegación de los sitios web que ha visitado.</p>

			<p>Hay muchos tipos de cookies. Las cookies se descargan a través del navegador de Internet de la persona usuaria la primera vez que visita este sitio web (en adelante también sitio). La siguiente vez que lo hace desde el mismo dispositivo, la cookie y la información almacenada en ella se envía de vuelta al sitio que la generó (cookie propia) o a un sitio web distinto al que pertenece (cookie de terceros).&nbsp;</p>

			<p>Estos terceros fuera de nuestro sitio web instalan las cookies cuando la persona usuaria se conecta a sus páginas y visita nuestro sitio. Nosotros no tenemos control ninguno sobre las cookies de estos sitios web. La persona usuaria deberá visitar las políticas de los sitios web de estos terceros proveedores de cookies, para obtener información sobre cómo usan las cookies.</p>

			<p>Las cookies propias y de terceros permiten al sitio web reconocer si la persona usuaria ya ha accedido al sitio a través de un navegador y, recoger una serie de datos que posteriormente son utilizados son distintos fines en función del tipo de cookie.</p>

			<h4>¿Quiénes utilizamos cookies en este sitio web?</h4>

			<p>En este sitio web utiliza cookies tanto Ferring, S.A.U. (en adelante Ferring) como editor y titular del mismo.</p>

			<h4>¿Qué cookies utilizamos en este sitio web, con qué finalidad y qué tipo de datos se recogen?</h4>

			<p>Este sitio web utiliza distintos tipos de cookies.</p>

			<p>Por un lado, cookies estrictamente necesarias: estas cookies aseguran funciones sin las cuales la persona usuaria no podría utilizar este Sitio. Estas cookies se encargan de llevar a cabo la transmisión de la comunicación a través de Internet y prestar el servicio solicitado explícitamente por la persona interesada. El uso de cookies estrictamente necesarias para los fines descritos anteriormente se basa en el interés legítimo de Ferring para garantizar el funcionamiento técnico correcto, el acceso al sitio y su uso, y la prestación del servicio que en su caso solicite la persona usuaria. Si la persona usuaria desactiva estas cookies, Ferring no podrá prestarle el servicio solicitado expresamente.

			</p><p>Otro tipo de cookies: Además de estas cookies, a continuación la persona usuaria puede ver otras cookies que utiliza el sitio web así como una descripción de la finalidad, si es propia o de terceros, datos que recoge, si conlleva la elaboración de perfiles y la existencia o no de transferencias internacionales con los datos tratados por las cookies.</p>


			<table class="table-cookies">
						<tbody><tr>
							<td><strong>Nombre</strong></td>
							<td><strong>Tipo</strong></td>
							<td><strong>Caducidad</strong></td>
							<td><strong>Finalidad</strong></td>
						</tr>
						<tr>
							<td>cookieControl</td>
							<td>Técnica</td>
							<td>1 año</td>
							<td>Se usa por el plugin de aviso de cookies para registrar que usted ha aceptado o rechazado que el sitio web use cookies.</td>
						</tr>
						<tr>
							<td>cookieControlPrefs</td>
							<td>Técnica</td>
							<td>1 año</td>
							<td>Se usa por el plugin de aviso de cookies para registrar que cookies ha aceptado.</td>
						</tr>
						<tr>
							<td>_ga</td>
							<td>Analítica – Google Analytics</td>
							<td>2 años</td>
							<td>Se usa para controlar los visitantes únicos de la web, almacena un identificador de cliente único.</td>
						</tr>
						<tr>
							<td>_gat</td>
							<td>Analítica – Google  Analytics</td>
							<td>10 minutos</td>
							<td>Se usa para diferenciar entre los diferentes objetos de seguimiento creados en la sesión.</td>
						</tr>
						<tr>
							<td>_gid</td>
							<td>Analítica – Google  Analytics</td>
							<td>24 horas</td>
							<td>Se usa para distinguir a los usuarios.</td>
						</tr>

			</tbody></table>


			<p>Ferring no es en ningún caso responsable del contenido y veracidad de las políticas y condiciones de uso y privacidad de los terceros, incluidas en estas condiciones a través de los enlaces y links publicados en esta política.</p>

			<p>En todo caso y en relación con las cookies, la persona usuaria puede configurar las opciones del navegador instalado en su equipo para permitir o desactivar el almacenamiento de las cookies, o configurar su eliminación. Para ello, la persona usuaria debe visitar o acceder a los sitios de cada proveedor que se indican en el cuadro anterior.</p>

			<h4>¿Cómo puede la persona usuaria configurar las cookies y autorizar o rechazar a Ferring y los terceros proveedores el uso de las cookies de este sitio web?</h4>

			<p>En relación con el uso de cookies de este sitio web según lo descrito en el apartado anterior, la persona usuaria debe saber que, excepto en el caso de las categorías de cookies que sean estrictamente necesarias para el funcionamiento del sitio,  es la propia persona usuaria quien puede autorizar el uso o rechazo de todas las cookies del sitio o autorizar unas y rechazar otras según sus preferencias, todo ello a través del "panel de configuración de cookies".

			</p><p>- Si la persona usuaria pincha Configuración de cookies la persona usuaria puede permitir la instalación de unas cookies y el rechazo de otras en función de sus preferencias, una a una.</p>
			<p>- Si la persona usuaria pincha Aceptar todas las cookies se instalarán todas las cookies propias y de terceros que tiene este sitio.</p>
			<p>- Si la persona usuaria pincha Rechazar todas las cookies salvo las cookies estrictamente necesarias, se desactivarán todas las cookies tanto las cookies propias como las de terceros. Además, las cookies propias se eliminarán. Pero, para eliminar las cookies de terceros, la persona usuaria tendrá que hacerlo ella misma a través del área de configuración de su navegador (véase el siguiente punto).</p>

			<p>Pese a la configuración inicial realizada en la primera visita del sitio, la persona usuaria puede en cualquier momento modificar la configuración a través del “panel de configuración de cookies”. </p>

			<p>Además, la persona usuaria tiene la opción de dejar de aceptar el uso de cookies en cualquier momento a través de su navegador, o dejar de aceptarlas cada vez que inicia una sesión de navegación en nuestro sitio web según se explica en el punto siguiente.</p>

			<h4>¿Cómo se puede desactivar o eliminar las cookies a través del navegador?</h4>

			<p>La mayoría de los navegadores permiten cambiar la configuración de cookies así como eliminarlas. Estos ajustes normalmente se encuentran en las ‘opciones’ o ‘Preferencias’ del menú del navegador de la persona usuaria.</p>

			<p>Por ello, la persona usuaria puede modificar la configuración de las cookies en su navegador de Internet. Esta configuración suele encontrarse en el menú «opciones», «herramientas» o «preferencias» del navegador. También puede consultar el menú «ayuda» del navegador. Los distintos navegadores utilizan distintos mecanismos para desactivar las cookies.</p>

			<p>La persona usuaria puede consultar los siguientes enlaces para obtener más información sobre cómo administrar la configuración de las cookies en los siguientes navegadores:</p>

			<p><a href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank" rel="noopener noreferrer" class="">Configuración de cookies en Internet Explorer</a></p>
			<p><a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectlocale=es&amp;redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we" target="_blank" rel="noopener noreferrer" class="">Configuración de cookies en Firefox</a></p>
			<p><a href="https://support.google.com/chrome/answer/95647?hl=es" target="_blank" rel="noopener noreferrer" class="">Configuración de cookies en Chrome</a></p>
			<p><a href="https://support.apple.com/kb/ph5042?locale=es_ES" target="_blank" rel="noopener noreferrer" class="">Configuración de cookies en Safari</a></p>

			<p>Por lo que respecta a la eliminación de las cookies a través del navegador, se aplican los mismos principios que para la desactivación&nbsp;de las cookies nuevas a través de la configuración del navegador.  Se recomienda a la persona usuaria que consulte la guía de referencia de cada uno de los navegadores.</p>

			<p>Se informa a la persona usuaria de que existen herramientas online para eliminar todas las cookies instaladas por los sitios web que visita. Puede consultar información sobre ello aqui.</p>

			<p>Si la persona usuaria visita este sitio desde distintos equipos, será necesario repetir la modificación de la configuración que la persona usuaria desee, en cada uno de los equipos.</p>

			<h4>¿Tratamos datos de carácter personal obtenidos a través de las cookies?</h4>

			<p>Ferring no vinculará las cookies datos personales de las personas usuarias del sitio web ni de terceros. En todo caso, para información sobre protección de datos personales  consulte nuestra Política de Privacidad de este sitio.</p>

			<h4>¿Qué legislación y jurisdicción es aplicable?</h4>

			<p>Estas condiciones generales se rigen por la legislación española y para cualquier litigio que pueda derivarse de su interpretación y/o aplicación, la persona usuaria renuncia a su fuero propio para someterte a los Juzgados y Tribunales de Madrid capital.</p>

			<h4>Actualización de esta política</h4>

			<p>Ferring puede actualizar estas condiciones y política de cookies. Cualquier modificación de estas condiciones será publicada en este sitio web. A partir del momento en que Ferring ponga a disposición de la persona usuaria las nuevas condiciones mediante su publicación en este sitio, estas nuevas condiciones serán de aplicación sustituyendo a las anteriormente publicadas. Es responsabilidad de la persona usuaria acceder periódicamente a estas condiciones publicadas en nuestro sitio web a fin de conocer en todo momento la última versión.</p>
    </div>
    <div class="popup-footer">
        <button type="button" (click)="closePoliticaCookies()">Aceptar</button>
    </div>
  </div>
</div>

<div class="popup" *ngIf="unsupportedBrowser">
  <div class="popup-content">
    <div class="popup-header">
      <h1>Navegador no soportado</h1>
    </div>
    <div class="popup-body">
			<p>Para el correcto funcionamiento de esta página web, le recomendamos el uso de los siguientes navegadores: Google Chrome, Mozilla Firefox, Apple Safari o Microsoft Edge. Muchas gracias.</p>
    </div>
  </div>
</div>

<router-outlet *ngIf="!unsupportedBrowser"></router-outlet>
