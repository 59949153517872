import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { LoginService } from '../login/login.service';
import { PopupService } from '../popup.service';
declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  showPanel = false;
  isPrehome = true;
  showPrivacyPolicy = false;
  microgramos!: number;
  totalMicrogramos = 0;
  ui!: number;
  dias!: number;
  totalUI = 0;
  plumas12 = 0;
  plumas36 = 0;
  plumas72 = 0;
  dosisTotal = 0;
  diferencia = 0;
  plumas = [
    { min: 0 ,   max: 16	,	plumas12: 1	, plumas36: 0	, plumas72: 0},
    { min: 17,   max: 28	,	plumas12: 2	, plumas36: 0	, plumas72: 0},
    { min: 29,   max: 40	,	plumas12: 0	, plumas36: 1	, plumas72: 0},
    { min: 41,   max: 52	,	plumas12: 1	, plumas36: 1	, plumas72: 0},
    { min: 53,   max: 64	,	plumas12: 2	, plumas36: 1	, plumas72: 0},
    { min: 65,   max: 76	,	plumas12: 0	, plumas36: 0	, plumas72: 1},
    { min: 77,   max: 88	,	plumas12: 1	, plumas36: 0	, plumas72: 1},
    { min: 89,   max: 100,	plumas12: 2	, plumas36: 0	, plumas72: 1},
    { min: 101,	max: 112,	plumas12: 0	, plumas36: 1	, plumas72: 1},
    { min: 113,	max: 124,	plumas12: 1	, plumas36: 1	, plumas72: 1},
    { min: 125,	max: 136,	plumas12: 2	, plumas36: 1	, plumas72: 1},
    { min: 137,	max: 148,	plumas12: 0	, plumas36: 0	, plumas72: 2},
    { min: 149,	max: 160,	plumas12: 1	, plumas36: 0	, plumas72: 2},
    { min: 161,	max: 172,	plumas12: 2	, plumas36: 0	, plumas72: 2},
    { min: 173,	max: 184,	plumas12: 0	, plumas36: 1	, plumas72: 2},
    { min: 185,	max: 196,	plumas12: 1	, plumas36: 1	, plumas72: 2},
    { min: 197,	max: 208,	plumas12: 2	, plumas36: 1	, plumas72: 2},
    { min: 209,	max: 220,	plumas12: 0	, plumas36: 0	, plumas72: 3},
    { min: 221,	max: 232,	plumas12: 1	, plumas36: 0	, plumas72: 3},
    { min: 233,	max: 244,	plumas12: 2	, plumas36: 0	, plumas72: 3},
    { min: 245,	max: 256,	plumas12: 0	, plumas36: 1	, plumas72: 3},
    { min: 257,	max: 268,	plumas12: 1	, plumas36: 1	, plumas72: 3},
    { min: 269,	max: 280,	plumas12: 2	, plumas36: 1	, plumas72: 3},
    { min: 281,	max: 292,	plumas12: 0	, plumas36: 0	, plumas72: 4}
  ];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private loginService: LoginService,
    private popupService: PopupService,
    protected $gaService: GoogleAnalyticsService
  ) { }

  ngOnInit(): void {
    this.isPrehome = this.route.snapshot.data.page === 'prehome';
    this.showPrivacyPolicy = this.route.snapshot.data.privacy;
    if (this.isPrehome && this.loginService.isLoggedIn()) {
      this.router.navigate(['/']);
    } else if (!this.isPrehome && !this.loginService.isLoggedIn()){
      this.router.navigate(['/login']);
    }

    if (this.showPrivacyPolicy) {
      this.router.navigate(['/']);
      this.showPoliticaPrivacidad();
    }
  }

  init(): void {
    this.router.navigate(['/login']);
  }

  onUpdateMicrogramos(): void{
    this.ui = this.microgramos * 15;
    this.calcTotal();
  }

  onUpdateUI(): void {
    this.microgramos = Math.round(this.ui / 15);
    this.calcTotal();
  }

  onUpdateDias(): void {
    this.calcTotal();
  }

  calcTotal(): void {
    this.totalMicrogramos = this.dias ? this.dias * this.microgramos : 0;
    this.totalUI = this.dias ? this.dias * this.ui : 0;
    this.calcPlumas();
  }

  calcPlumas(): void {
    this.plumas12 = 0;
    this.plumas36 = 0;
    this.plumas72 = 0;
    this.plumas.forEach(pluma => {
      if (pluma.min <= this.totalMicrogramos && pluma.max >= this.totalMicrogramos) {
        this.plumas12 = pluma.plumas12;
        this.plumas36 = pluma.plumas36;
        this.plumas72 = pluma.plumas72;
        this.dosisTotal = (this.plumas12 * 12) +  (this.plumas36 * 36) + (this.plumas72 * 72);
        this.diferencia = this.dosisTotal - this.totalMicrogramos;
      }
    });

    //analytics
    if ($.fn.ihavecookies.preference('analytics') === true) {
      this.$gaService.event('calculation', 'cálculo plumas');
    }
  }
  showAvisoLegal(): void { this.popupService.showPopUpAvisoLegal(); }
  showPoliticaPrivacidad(): void { this.popupService.showPopUpPoliticaPrivacidad(); }
  showPoliticaUso(): void { this.popupService.showPopUpPoliticaUso(); }
  showPoliticaCookies(): void { this.popupService.showPopUpPoliticaCookies(); }

}
