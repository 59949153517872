import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PopupService {
  private showPopUpAvisoLegalSource = new Subject();
  private showPopUpAvisoFarmacovigilanciaSource = new BehaviorSubject(true);
  private showPopUpPoliticaUsoSource = new Subject();
  private showPopUpPoliticaPrivacidadSource = new Subject();
  private showPopUpPoliticaCookiesSource = new Subject();

  showPopUpAvisoLegal$ = this.showPopUpAvisoLegalSource.asObservable();
  showPopUpAvisoFarmacovigilancia$ = this.showPopUpAvisoFarmacovigilanciaSource.asObservable();
  showPopUpPoliticaUso$ = this.showPopUpPoliticaUsoSource.asObservable();
  showPopUpPoliticaPrivacidad$ = this.showPopUpPoliticaPrivacidadSource.asObservable();
  showPopUpPoliticaCookies$ = this.showPopUpPoliticaCookiesSource.asObservable();

  constructor() { }

  showPopUpAvisoLegal(): void { this.showPopUpAvisoLegalSource.next(true); }
  closePopUpAvisoLegal(): void { this.showPopUpAvisoLegalSource.next(false); }

  showPopUpAvisoFarmacovigilancia(): void { this.showPopUpAvisoFarmacovigilanciaSource.next(true); }
  closePopUpAvisoFarmacovigilancia(): void { this.showPopUpAvisoFarmacovigilanciaSource.next(false); }

  showPopUpPoliticaUso(): void { this.showPopUpPoliticaUsoSource.next(true); }
  closePopUpPoliticaUso(): void { this.showPopUpPoliticaUsoSource.next(false); }

  showPopUpPoliticaPrivacidad(): void { this.showPopUpPoliticaPrivacidadSource.next(true); }
  closePopUpPoliticaPrivacidad(): void { this.showPopUpPoliticaPrivacidadSource.next(false); }

  showPopUpPoliticaCookies(): void { this.showPopUpPoliticaCookiesSource.next(true); }
  closePopUpPoliticaCookies(): void { this.showPopUpPoliticaCookiesSource.next(false); }
}
