<img src="assets/img/ferring.png" alt="Ferring" class="logo" [ngClass]="{'not-visible':showPanel}"/>
<div class="main-content" [ngClass]="{'not-visible':showPanel}">
  <div class="calc-form-wrapper">
    <h2>Combinación plumas</h2>
    <h3>Folitropina Delta</h3>
    <div class="dosis">
      <label>Dosis diaria</label>
      <div class="input-wrapper">
        <input numbersOnly autocomplete="off" type="text" name="dosis-microg" [readonly]="isPrehome" [disabled]="isPrehome" [(ngModel)]="microgramos" (keyup)="onUpdateMicrogramos()"/>
        <span>µg</span>
      </div>
      <div class="input-wrapper">
        <input numbersOnly autocomplete="off" type="text" name="dosis-ui" [readonly]="isPrehome" [disabled]="isPrehome" [(ngModel)]="ui" (keyup)="onUpdateUI()"/>
        <span>UI <sup>1,2</sup></span>
      </div>
    </div>
    <div class="tratamiento">
      <label>Tratamiento</label>
      <div class="input-wrapper">
        <input numbersOnly autocomplete="off" type="text" name="dias" [readonly]="isPrehome"  [disabled]="isPrehome" [(ngModel)]="dias" (change)="onUpdateDias()"/>
        <span>Días</span>
      </div>
    </div>

    <button type="button" (click)="showPanel = !showPanel" *ngIf="!isPrehome"><span *ngIf="showPanel">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span *ngIf="!showPanel">Iniciar</span></button>
    <button type="button" (click)="init()" *ngIf="isPrehome">Iniciar</button>
  </div>
  <footer [ngClass]="{'not-visible':showPanel || isPrehome}">
    <div class="calc-references" [ngClass]="{'not-visible':showPanel || isPrehome}">
      1. Arce JC, <i>et al</i>. Establishing the follitropin delta dose providing a comparable ovarian response as 150 IU/day follitropin alfa for controlled ovarian stimulation. Reproductive BioMedicine Online 2020;41(4):616‐22. https://doi.org/10.1016/j.rbmo.2020.07.<br/>
      2. Ficha técnica de Rekovelle<sup>®</sup>. Disponible <a href="/assets/FT_Rekovelle JUL23_v1.pdf" target="_blank">aquí</a>
    </div>
    <nav>
      <a (click)="showAvisoLegal()">Aviso legal</a>
      <a (click)="showPoliticaUso()">Política de uso</a>
      <a (click)="showPoliticaPrivacidad()">Política de privacidad</a>
      <a (click)="showPoliticaCookies()">Política de cookies</a>
    </nav>
  </footer>
</div>


<div class="bottom-panel" [ngClass]="{'visible':showPanel}">
  <div class="result-wrapper">
    <div class="equivalencias-result">
      <div class="equivalencias-result-left">
        <div class="dosis">
          <label>Dosis diaria</label>
          <div class="dosis-result dosis-diaria">
            <span>{{microgramos}} µg</span>
            <span class="large">{{ui}} UI *</span>
          </div>
        </div>
        <div class="dosis">
          <label>Total<sup>1,2</sup></label>
          <div class="dosis-result">
            <span>{{totalMicrogramos}} µg</span>
            <span class="large">{{ totalUI }} UI</span>
          </div>
        </div>
      </div>
      <div class="equivalencias-result-right">
        <div class="dosis dosis-plumas">
          <label>Plumas Folitropina Delta</label>
          <div class="dosis-result">
            <div class="pluma">
              72 µg
              <span class="circle orange">{{plumas72}}</span>
            </div>
            <div class="pluma">
              36 µg
              <span class="circle green">{{plumas36}}</span>
            </div>
            <div class="pluma">
              12 µg
              <span class="circle purple">{{plumas12}}</span>
            </div>

          </div>
        </div>
        <div class="dosis dosis-diferencia">
          <label>Diferencia</label>
          <div class="dosis-result">
            <span class="large">{{diferencia}} µg</span>
          </div>
        </div>
      </div>
    </div>
    <div class="equivalencias-bottom">
      <div class="equivalencias-bottom-left">        
        <p>*Los datos de los ensayos clínicos sugieren que una dosis diaria de 10 mcg (IC 95%: 9,2;10,8) de REKOVELLE<sup>®</sup> proporciona, para la mayoría de los pacientes, una respuesta ovárica cercana a la obtenida con 150 UI/día de folitropina alfa.<sup>1</sup></p>
      </div>
      <div class="equivalencias-bottom-right">
        <p>1. Arce JC, <i>et al</i>. Establishing the follitropin delta dose providing a comparable ovarian response as 150 IU/day follitropin alfa for controlled ovarian stimulation. Reproductive BioMedicine Online 2020;41(4):616‐22. https://doi.org/10.1016/j.rbmo.2020.07.006.</p>
        <p>2. Ficha técnica de Rekovelle<sup>®</sup>. Disponible <a href="/assets/FT_Rekovelle JUL23_v1.pdf" target="_blank">aquí</a></p>
        <p class="ref">ES-REK-2000009 Julio 2024</p>
      </div>
    </div>
  </div>

</div>
