import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  key = 'dosisdelta';
  key2 = 'ferringdelta';
  sessionDuration = 600 * 1000; // segundos
  constructor() { }

  login(password: string): boolean {
    if (password === this.key || password === this.key2) {
      const now = new Date().getTime() + this.sessionDuration;
      localStorage.setItem('token', btoa(now.toString()));
      return true;
    } else {
      return false;
    }
  }

  isLoggedIn(): boolean {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = atob(token);
        const now = new Date().getTime();
        if (decodedToken && now > +decodedToken) {
          return false;
        } else {
          return true;
        }
      } catch (e) {
        return false;
      }
    } else {
      return false;
    }
  }
}
